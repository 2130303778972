// material-ui
// import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import {
    Box,
    Button,
    Stack,
    Paper,
    MenuList,
    MenuItem,
    Typography,
    Divider,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    ListItemIcon,
    List,
    ListItem,
    ListItemText,
    ButtonBase,
    Link,
    IconButton,
    SwipeableDrawer,
    Collapse,
    ClickAwayListener,
    Grid,
    Select,
    InputBase,
    FormControl,
    Popover,
    SvgIcon,
    ListItemButton
} from '@mui/material';
 
// import { Link } from 'react-router-dom';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuIcon from '@mui/icons-material/Menu';
import ClsoeIcon from '@mui/icons-material/Close';
import LanguageIcon from '@mui/icons-material/Language';
import DownloadIcon from '@mui/icons-material/DownloadingOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
 

// import Logo from 'assets/images/tdxLogo.jpg';
import MenuBg from 'assets/images/home/menu-personal-bg.png';
import MenuArtA from 'assets/images/home/menu-img1.png';
import MenuArtB from 'assets/images/home/menu-img2.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


// import QRIos from 'assets/images/home/ios-qr.png'
// import QRAndroid from 'assets/images/home/android-qr.png'
import QRBeta from 'assets/images/home/beta-qr.png'
import QRIosAndroid from 'assets/images/home/ios_andriod_qrcode.png'

import AppleStore from 'assets/images/home/appstore.png';
import AppleStoreBeta from 'assets/images/home/applestoreBeta.png';
import GoogleMarket from 'assets/images/home/googlestore.png';

// import MenuIcon from 'assets/images/home/menu-icon.png';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { useI18n } from 'hooks/useI18n';
import { withStyles } from '@mui/styles';
import Check from '@mui/icons-material/Check';
import { I18n } from 'i18n-js'; 
import { useState } from 'react';
// import BilingIcon from 'assets/images/icons/bilingual-login.svg';

 

const BilingSwitchBtn = () => {
    const { setI18n, locale } = useI18n();
    const toggleLanguage = (a: any) => {
        setI18n(a ?? 'en');
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [isHovered, setIsHovered] = React.useState(false);
    const hoverTimeout = React.useRef<NodeJS.Timeout | null>(null);

    const handleMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsHovered(true);

        // Clear any pending close timeouts when hovering again
        if (hoverTimeout.current) {
            clearTimeout(hoverTimeout.current);
        }
    };

    const handleMouseLeave = () => {
        // Set a small delay before closing the popover
        hoverTimeout.current = setTimeout(() => {
           setIsHovered(false);
           
        }, 500); // Delay in ms
    };

    const handlePopoverEnter = () => {
        setIsHovered(true);
        // Clear any pending timeouts when the popover is entered
        if (hoverTimeout.current) {
            clearTimeout(hoverTimeout.current);
        }
    };

    const handlePopoverLeave = () => {
        setIsHovered(false);
         
    };

    const handleClickLan = (e: string) => {
        setAnchorEl(null);
        toggleLanguage(e);
    };

    const theme = useTheme();
    const open = isHovered && Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Box
            sx={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                ml: '12px',
                px: {
                    md: 0,
                    lg: 0,
                },
            }}
        >
            <div>
                <IconButton
                    aria-describedby={id}
                    onClick={handleMouseEnter}
                    onMouseEnter={handleMouseEnter}
                   onMouseLeave={handleMouseLeave}
                    sx={{
                        color: theme.palette.common.white,
                        '&:hover': {
                            opacity: 0.8,
                        },
                    }}
                >
                    <LanguageIcon />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    disablePortal
                    anchorEl={anchorEl}
                    onClose={handleMouseLeave}
                    
                   
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '1px !important',
                            padding: '5px',
                            mt: '6px',
                        },
                        '&.MuiPopover-root':{
                            zIndex:'-100 !important',
                        },
                        
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Stack   onMouseEnter={handlePopoverEnter}
                    onMouseLeave={handlePopoverLeave}>
                    <MenuItem
                        sx={{
                            '&.MuiMenuItem-root': {
                                borderRadius: '4px !important',
                                justifyContent: 'space-between',
                                fontFamily:"GothamBold",
            fontWeight:"bold"
                            },
                        }}
                        onClick={() => handleClickLan('en')}
                        selected={locale === 'en'}
                        value="en"
                    >
                        English
                        {locale === 'en' && (
                            <ListItemIcon sx={{ ml: 1, mr: 0, paddingLeft: '20px' }}>
                                <Check sx={{ width: '15px' }} />
                            </ListItemIcon>
                        )}
                    </MenuItem>

                    <MenuItem
                        sx={{
                            '&.MuiMenuItem-root': {
                                borderRadius: '4px !important',
                                justifyContent: 'space-between',fontFamily:"GothamBold",
            fontWeight:"bold"
                            },
                        }}
                        onClick={() => handleClickLan('zh')}
                        selected={locale === 'zh'}
                        value="zh"
                    >
                        繁體中文
                        {locale === 'zh' && (
                            <ListItemIcon sx={{ ml: 1, mr: 0, paddingLeft: '20px' }}>
                                <Check sx={{ width: '15px' }} />
                            </ListItemIcon>
                        )}
                    </MenuItem>

                    <MenuItem
                        sx={{
                            '&.MuiMenuItem-root': {
                                borderRadius: '4px !important',
                                justifyContent: 'space-between',fontFamily:"GothamBold",
            fontWeight:"bold"
                            },
                        }}
                        onClick={() => handleClickLan('cn')}
                        selected={locale === 'cn'}
                        value="cn"
                    >
                        简体中文
                        {locale === 'cn' && (
                            <ListItemIcon sx={{ ml: 1, mr: 0, paddingLeft: '20px' }}>
                                <Check sx={{ width: '15px' }} />
                            </ListItemIcon>
                        )}
                    </MenuItem>
                    </Stack>
                </Popover>
            </div>
        </Box>
    );
};


const BilingSwitch = ()=> {
    const { setI18n, locale,i18n } = useI18n();
    const toggleLanguage = (a: any) => {
        setI18n(a ?? 'en');
    };

   
 

    const handleClickLan = (e: string) => {
       
        toggleLanguage(e);
    };

   
    const [open, setOpen] = React.useState(false);
  
    const handleClick = () => {
      setOpen(!open);
    };
  
    return (
      <List
        sx={{ width: '100%',  '& .MuiTypography-root':{
            color: (theme)=>`${theme.palette.primary.main} !important`,
            fontFamily:"GothamBold",
            fontWeight:"bold"
        },'& .MuiListItemButton-root:hover':{
            bgcolor:(theme)=>`white`,
            
            color:(theme)=>`${theme.palette.primary.main} !important`
        },'& .MuiListItemButton-root':{ 
             
        },'& .MuiListItemButton-root.Mui-selected':{
            borderRadius:"4px !important",
            backgroundColor:"rgba(0, 31, 161, 0.08) !important"
        },  paddingTop:'0px !important',
        paddingBottom:"0px !important",}}
        component="nav"
        aria-labelledby="nested-list-subheader"
        
      >
         
        {/* <ListItemButton onClick={handleClick} selected={false} disableRipple sx={{
            paddingTop:'6px !important',
            paddingBottom:"6px !important",
        }}>
          
          <ListItemText sx={{}} primary={   i18n.t("language")} />
          {open ? <ExpandLess /> : <ExpandMoreIcon />}
        </ListItemButton> */}

        <MenuItem selected={false} sx={{
                        '&.MuiMenuItem-root':{
                            borderRadius: '4px !important',
                            justifyContent:"space-between",
                            fontFamily:"GothamBold"
                        },
                        fontWeight:"bold"
                    }} onClick={handleClick}  >
                    
                    {   i18n.t("language")} 
                   <ListItemIcon sx={{ml:2,mr:"-4px",paddingLeft:"25px"}}>
                    {open ? <ExpandLess  /> : <ExpandMoreIcon />}
          </ListItemIcon> 
                    
                    </MenuItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{pt:"2px"}}>
            <ListItemButton sx={{ pl: 4 }} selected={locale === 'en'} onClick={()=>handleClickLan("en")}>
              
              <ListItemText   primary="English" />
              {locale === 'en' && <Check sx={{ width: '15px' }} />}
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} selected={locale === 'zh'} onClick={()=>handleClickLan("zh")}>
              
              <ListItemText primary="繁體中文" />
              {locale === 'zh' && <Check sx={{ width: '15px' }} />}
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} selected={locale === 'cn'} onClick={()=>handleClickLan("cn")}>
              
              <ListItemText primary="简体中文" />
              {locale === 'cn' && <Check sx={{ width: '15px' }} />}
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    );
  }

const BilingSwitch1 = () => {
    const { setI18n, locale,i18n } = useI18n();
    const toggleLanguage = (a: any) => {
        setI18n(a ?? 'en');
    };

   
 

    const handleClickLan = (e: string) => {
       
        toggleLanguage(e);
    };

   

    return (
        
            <Stack   >
                    <MenuItem
                        sx={{
                            '&.MuiMenuItem-root': {
                                borderRadius: '4px !important',
                                justifyContent: 'space-between',
                            },
                        }}
                        onClick={() => handleClickLan('en')}
                        selected={locale === 'en'}
                        value="en"
                    >
                        English
                        {locale === 'en' && (
                            <ListItemIcon sx={{ ml: 1, mr: 0, paddingLeft: '20px' }}>
                                <Check sx={{ width: '15px' }} />
                            </ListItemIcon>
                        )}
                    </MenuItem>

                    <MenuItem
                        sx={{
                            '&.MuiMenuItem-root': {
                                borderRadius: '4px !important',
                                justifyContent: 'space-between',
                            },
                        }}
                        onClick={() => handleClickLan('zh')}
                        selected={locale === 'zh'}
                        value="zh"
                    >
                        繁體中文
                        {locale === 'zh' && (
                            <ListItemIcon sx={{ ml: 1, mr: 0, paddingLeft: '20px' }}>
                                <Check sx={{ width: '15px' }} />
                            </ListItemIcon>
                        )}
                    </MenuItem>

                    <MenuItem
                        sx={{
                            '&.MuiMenuItem-root': {
                                borderRadius: '4px !important',
                                justifyContent: 'space-between',
                            },
                        }}
                        onClick={() => handleClickLan('cn')}
                        selected={locale === 'cn'}
                        value="cn"
                    >
                        简体中文
                        {locale === 'cn' && (
                            <ListItemIcon sx={{ ml: 1, mr: 0, paddingLeft: '20px' }}>
                                <Check sx={{ width: '15px' }} />
                            </ListItemIcon>
                        )}
                    </MenuItem>
                    </Stack>
         
    );
};


const Image = styled('img')({
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: 0
});
const QRDownloadItem = ({url,icon,link,label}:any)=>{
    console.log("")
    return <Stack alignItems="center">
       
        <Image width={150} height={150} src={url} />
        <Typography
                        sx={{ mb: 1,mt:1, fontSize: '0.8rem',width:"150px" }}
                        variant="heading1"
                        component="h2"
                        color="primary.dark"
                        textAlign="center"
                    >
                        {label}
                    </Typography>
                    {icon &&  <Box target="_blank" component="a" href={link} sx={{ cursor: 'pointer', borderRadius: '10px' }}>
        <Image width={100} src={icon} />
        </Box>}
       

    </Stack>
}

const DownloadShortCut = () => {
    const { setI18n, locale,i18n } = useI18n();
    const toggleLanguage = (a: any) => {
        setI18n(a ?? 'en');
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [isHovered, setIsHovered] = React.useState(false);
    const hoverTimeout = React.useRef<NodeJS.Timeout | null>(null);

    const handleMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsHovered(true);

        // Clear any pending close timeouts when hovering again
        if (hoverTimeout.current) {
            clearTimeout(hoverTimeout.current);
        }
    };

    const handleMouseLeave = () => {
        // Set a small delay before closing the popover
        hoverTimeout.current = setTimeout(() => {
            setIsHovered(false); 
        }, 500); // Delay in ms
    };

    const handlePopoverEnter = () => {
        setIsHovered(true);
        // Clear any pending timeouts when the popover is entered
        if (hoverTimeout.current) {
            clearTimeout(hoverTimeout.current);
        }
    };

    const handlePopoverLeave = () => {
        setIsHovered(false); 
    };

    const theme = useTheme();

    const open = isHovered && Boolean(anchorEl);
    const id = open ? 'download-pop' : undefined;

    return (
        <Box
            sx={{
                display: 'flex',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                px: '0'
            }}
        >
            <div>
                <IconButton
                    aria-describedby={id}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    sx={{
                        color: theme.palette.common.white,
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                >
                    {/* <DownloadIcon /> */}

                    <SvgIcon>
  {/* credit: plus icon from https://heroicons.com/ */}
  <svg xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={0.5}
    stroke="currentColor">
  <path strokeLinecap="round"
  fill="rgb(255, 255, 255)" 
      strokeLinejoin="round" color='white' d="M7.8 0C6.12 0 5.14-.052 4.185.436a4 4 0 0 0-1.748 1.748C1.948 3.14 2 4.12 2 5.8V7a1 1 0 0 0 1 1 1 1 0 0 0 1-1V5.8c0-1.68.052-2.381.219-2.708.192-.377.496-.681.873-.873C5.419 2.052 6.12 2 7.8 2h8.398c1.68 0 2.382.052 2.71.219.376.192.68.496.872.873.167.327.219 1.029.219 2.709V7a1 1 0 0 0 1 1 1 1 0 0 0 1-1V5.8c0-1.68.052-2.66-.436-3.616A4 4 0 0 0 19.816.436C18.86-.052 17.88 0 16.2 0Zm4.366 5a1 1 0 0 0-1 1v7.586l-2.459-2.459a1 1 0 0 0-1.414 0 1 1 0 0 0 0 1.414l4.166 4.166a1 1 0 0 0 1.414 0l4.168-4.166a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0l-2.461 2.459V6a1 1 0 0 0-1-1zM3 14a1 1 0 0 0-1 1v1.2c0 1.68-.052 2.66.436 3.616a4 4 0 0 0 1.748 1.748C5.14 22.052 6.12 22 7.8 22h8.398c1.68 0 2.661.052 3.617-.436a4 4 0 0 0 1.748-1.748c.488-.956.436-1.937.436-3.617V15a1 1 0 0 0-1-1 1 1 0 0 0-1 1v1.2c0 1.68-.052 2.38-.219 2.708a1.995 1.995 0 0 1-.873.873c-.327.167-1.029.219-2.709.219H7.801c-1.68 0-2.382-.052-2.71-.219a1.995 1.995 0 0 1-.872-.873C4.052 18.581 4 17.88 4 16.2V15a1 1 0 0 0-1-1Z" />
  </svg>
</SvgIcon>
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    disablePortal 
                   
                    anchorEl={anchorEl}
                    onClose={handlePopoverLeave}
                   //  onMouseEnter={handlePopoverEnter}
         
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '1px !important',
                            padding: "5px",
                            mt: '6px',
                          
                        },
                        '&.MuiPopover-root':{
                            zIndex:'-100 !important',
                        },
                    }}
                     
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                >
                    {/* <Box  onMouseOverCapture={(e)=>{
                        console.log("hhhh121212")
                        e?.stopPropagation();
                        handlePopoverEnter()
                    }} sx={{position:"absolute",top:"-60px",right:0,width:"100px",height:"100px",zIndex:1301}} />  */}
                    <Stack sx={{ py: 2, px: 2 }} 
                    onMouseEnter={handlePopoverEnter}
                    onMouseLeave={handlePopoverLeave}>
                        {/* <QRDownloadItem label={i18n.t("scanForIos")} url={QRIos} icon={AppleStore} link="https://apps.apple.com/hk/app/tdx-anywhere/id1566072479?l=en-GB?l=en&platform=iphone" />
                        <Divider  sx={{ my: 2 }} />
                        <QRDownloadItem label={i18n.t("scanForAndroid")} url={QRAndroid} icon={GoogleMarket} link="https://play.google.com/store/apps/details?id=io.tdx.app" /> */}
                        <QRDownloadItem label={i18n.t("scanForIosAndroid")} url={QRIosAndroid}  />
                        {/* <Divider sx={{ my: 2 }} />
                        <QRDownloadItem label={i18n.t("scanForBeta")} url={QRBeta} icon={AppleStoreBeta} link="https://testflight.apple.com/join/a05kNs2Q" /> */}
                    {/* <Button
                    variant="contained"
                    // component={Link}
                    // href="/products/yield-enhancement-strategy#incomePlus"
                    
                    sx={{
                         
                        fontSize: '0.6rem',

                       
                        width:"170px",
                        alignSelf: {
                            xs: 'center',
                            md: 'auto'
                        }
                    }}
                >
                   More Download Options
                </Button> */}
                    </Stack>
                </Popover>
            </div>
        </Box>
    );
};



 

const NavItem = (props: any) => {
    const { name, handleClick, route } = props;
    const url = `/${route.toLowerCase()}`;
    const { i18n } = useI18n();
    let delayTimer: any = null;

    const delayAction = (e: any) => {
        if (delayTimer) {
            return;
        }
        delayTimer = setTimeout(() => {
            handleClick && handleClick(e);
            if (delayTimer) {
                clearTimeout(delayTimer);
                delayTimer = null;
            }
        }, 700);
    };

    const onMouseLeave = () => {
        console.log('leave');
        if (delayTimer) {
            clearTimeout(delayTimer);
            delayTimer = null;
        }
    };

    return (
        <Button
            onMouseOver={delayAction}
            onMouseLeave={onMouseLeave}
            variant="text"
            component={Link}
            href={url}
            sx={{
                fontSize: '1rem',
                color: (theme) => theme.palette.common.white,
                fontFamily: '',
                fontWeight:50,
                '&:hover': {
                    opacity: 0.8
                }
            }}
        >
            {i18n.t(name.toLowerCase())}
        </Button>
    );
};

const DrawerMenuIcon = (props:any) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const  {i18n} = useI18n();
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        console.log("triggered")
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setIsOpen(open);
        props?.onDrawerChange(open);
    };

    const navigate = useNavigate();
    const location = useLocation();
    const {pathname:routename}=location;
    
    console.log(routename);
     
    
    const iOS =
  typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const handleMobileMenuSelect = (nodeId: string) => {
        switch (nodeId) {
            case '1':
                navigate('/company');
                break;
            case '2':
                navigate('/products');
                break;
            case '3':
                navigate('/products/yield-enhancement-strategy');
                break;
            case '4':
                navigate('/products/directional-exposure-strategy');
                break;
            case '5':
                navigate('/products/tailor-strategy');
                break;
            case '6':
                navigate('/insights');
                break;
            case '7':
                navigate('/');
                break;
            case '8':
                navigate('/platform');
            
                
                break;
            default:
                console.log("e");
        }
        setIsOpen(false);
    };

    const goToTele = () => {
        window.open(`${process.env.REACT_APP_BASE_URL}/tdx/login`);
    };
    const goToReg = () => {
        window.open(`${process.env.REACT_APP_BASE_URL}/tdx/register`);
    };

    return (
        <>
            <IconButton
                color="inherit"
                size="large"
                onClick={toggleDrawer(!isOpen)}
                sx={{
                    color:(theme) =>isOpen?theme.palette.primary.main:theme.palette.common.white,
                    display: {
                        md: 'none'
                    },
                    p: 0,
                    ml: 1
                }}
            >
                {isOpen?<ClsoeIcon
                    sx={{
                        '& .MuiSvgIcon-root': {
                            fontSize: '2rem'
                        }
                    }}
                />:<MenuIcon
                sx={{
                    '& .MuiSvgIcon-root': {
                        fontSize: '2rem'
                    }
                }}
            />}
            </IconButton>

            <SwipeableDrawer
                anchor="top"
                open={isOpen}
                disablePortal
                disableBackdropTransition={!iOS} disableDiscovery={iOS}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                sx={{zIndex:"-1 !important"}}
            >
                <Stack sx={{px:2,pb:2 }}>
                    <Box sx={{mt:"-3px",zIndex:1300}}>
                <ButtonBase disableRipple component={Link} href="/home"  >
                    
                <img src="/tdxLogo.svg" alt="tdx" width={150} />
                </ButtonBase>
                </Box>
                <Box mt={3} />
                <Stack flexDirection="column" sx={{  }}>
                <MenuItem selected={routename== "/"} sx={{
                        '&.MuiMenuItem-root':{
                            borderRadius: '4px !important',
                            justifyContent:"space-between",
                            fontFamily:"GothamBold"
                        },
                        fontWeight:"bold"
                    }} onClick={()=>handleMobileMenuSelect("7")}  >
                    
                    {i18n.t("home")}
                    { routename== "/"&&<ListItemIcon sx={{ml:1,mr:0,paddingLeft:"20px"}}>
            <Check sx={{width:"15px"}} />
          </ListItemIcon>}
                    
                    </MenuItem>

                    <MenuItem selected={routename== "/company"} sx={{
                        '&.MuiMenuItem-root':{
                            borderRadius: '4px !important',
                            justifyContent:"space-between",
                            fontWeight:"bold",fontFamily:"GothamBold"
                        }
                    }} onClick={()=>handleMobileMenuSelect("1")}  >
                    
                    {i18n.t("company")}
                    { routename== "/company"&&<ListItemIcon sx={{ml:1,mr:0,paddingLeft:"20px"}}>
            <Check sx={{width:"15px"}} />
          </ListItemIcon>}
                    
                    </MenuItem>

                    <MenuItem selected={routename== "/platform"} sx={{
                        '&.MuiMenuItem-root':{
                            borderRadius: '4px !important',
                            justifyContent:"space-between",
                            fontWeight:"bold",fontFamily:"GothamBold"
                        }
                    }} onClick={()=>handleMobileMenuSelect("8")}  >
                    
                    {i18n.t("platforms")}
                    { routename== "/platform"&&<ListItemIcon sx={{ml:1,mr:0,paddingLeft:"20px"}}>
            <Check sx={{width:"15px"}} />
          </ListItemIcon>}
                    
                    </MenuItem>

                    <MenuItem selected={routename== "/insights"} sx={{
                        '&.MuiMenuItem-root':{
                            borderRadius: '4px !important',
                            justifyContent:"space-between",
                            fontWeight:"bold",fontFamily:"GothamBold"
                        }
                    }} onClick={()=>handleMobileMenuSelect("6")}  >
                    
                    {i18n.t("insights")}
                    { routename== "/insights"&&<ListItemIcon sx={{ml:1,mr:0,paddingLeft:"20px"}}>
            <Check sx={{width:"15px"}} />
          </ListItemIcon>}
                    
                    </MenuItem>

                </Stack>
                <BilingSwitch /> 
                <Stack   gap={1} sx={{pt:2}}>

                <Button
                        // component={Link}
                        variant="text"
                        // href="/login"
                        onClick={goToTele}
                        sx={{
                            fontSize: '1rem',
                            lineHeight: '1rem',
                            height: '2.5rem',
                            fontFamily: 'GothamBold',
                            fontWeight: '500',
                            color: (theme)=>theme.palette.primary.main,
                          
                            border: '1px solid',
                         
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                    >
                        {i18n.t('login')}
                    </Button>
                    <Button
                        // component={Link}
                        variant="text"
                        // href="/login"
                        onClick={goToReg}
                        sx={{
                            fontSize: '1rem',
                            lineHeight: '1rem',
                            height: '2.5rem',
                            fontFamily: 'GothamBold',
                            fontWeight: '500',
                            color:(theme)=>theme.palette.common.white,
                            background:(theme)=>theme.palette.primary.main,
                           
                           
                            border: '1px solid',
                          
                          
                        }}
                    >
                        {i18n.t('signUp')}
                    </Button>

                </Stack>


                </Stack>
                
                
            </SwipeableDrawer>
        </>
    );
};
const Header = () => {
    // const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState('');

    // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     console.log('aaa', event);
    //     setAnchorEl('product');
    // };

    const handleClose = () => {
        setAnchorEl('');
    };

    const goToTele = () => {
        window.open(`${process.env.REACT_APP_BASE_URL}/tdx/login`);
    };
    const goToReg = () => {
        window.open(`${process.env.REACT_APP_BASE_URL}/tdx/register`);
    };

    const navigate = useNavigate();

    const goHome=()=>{
        console.log("dd")
        navigate("/")
    }

    const { i18n } = useI18n();

    const [isOpenDrawer,setDrawer] = useState(false)

    const open = Boolean(anchorEl);
    // const ProductMenu = () => {
    //     if (open) {
    //         return <PopoverMenu handleClick={handleClose} />;
    //     }
    //     return null;
    // };
    // const id = open ? 'product' : undefined;

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: {
                        md: 0,
                        lg: 2
                    }
                }}
            >
                <ButtonBase onClick={goHome}  disableRipple component={Link} href="/home"  >
                    <Box sx={{zIndex:"-10 !important"}}>
                <img   src="/tdxLogoWhite.svg" alt="tdx" width={150} />
                </Box>
                </ButtonBase>
                {/* <ButtonBase disableRipple component={Link} href="/home">
                    <img src="/tdxLogo.svg" alt="tdx" width={150} />
                </ButtonBase>
                <ButtonBase disableRipple component={Link} href="/home">
                    <img src="/tdxLogo.png" alt="tdx" width={150} />
                </ButtonBase> */}
                <Stack
                    sx={{
                        flexGrow: 1,
                        ml: 5,
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'flex-start'
                    }}
                    direction="row"
                    spacing={4}
                    pr={4}
                >
                    <NavItem name="home" route="home" />
                    <NavItem name="company" route="company" />
                    <NavItem name="platforms" route="platform" />
                    {/* <NavItem name="Products" aria-describedby={id} handleClick={handleClick} /> */}

                    {/* <NavItem name="Platform" /> */}
                    <NavItem name="insights" route="insights" />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Button
                        // component={Link}
                        variant="text"
                        // href="/login"
                        onClick={goToTele}
                        sx={{
                            fontSize: '1rem',
                            lineHeight: '1rem',
                            height: '2.5rem',
                            fontFamily: 'GothamBold',
                            fontWeight: '500',
                            color: 'white',
                            display: {
                                xs: 'none',
                                md: 'block'
                            },
                            border: '1px solid',
                            px: 4,
                            py: 0,
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                    >
                        {i18n.t('login')}
                    </Button>
                    <Button
                        // component={Link}
                        variant="text"
                        // href="/login"
                        onClick={goToReg}
                        sx={{
                            fontSize: '1rem',
                            lineHeight: '1rem',
                            height: '2.5rem',
                            fontFamily: 'GothamBold',
                            fontWeight: '500',
                            color:(theme)=>theme.palette.primary.main,
                            background:(theme)=>theme.palette.common.white,
                            ml:1,
                            display: {
                                xs: 'none',
                                md: 'block'
                            },
                            border: '1px solid',
                            px: 3,
                            py: 0,
                            '&:hover': {
                                opacity: 0.8,
                                color:(theme)=>theme.palette.primary.main,
                                background:(theme)=>theme.palette.common.white,
                            }
                        }}
                    >
                        {i18n.t('signUp')}
                    </Button>
                    {/* <Button
                        variant="contained"
                        href="/tdx/register"
                        component={Link}
                        sx={{
                            padding: {
                                md: '.5rem 1rem',
                                sx: 'px 1rem'
                            },
                            lineHeight: '100%',
                            marginLeft: '2rem',
                            fontSize: '.875rem',
                            fontFamily: 'GothamBold',
                            fontWeight: 'Bold',
                            textTransform: 'none',
                            display: {
                                xs: 'none',
                                md: 'block'
                            }
                        }}
                    >
                        {i18n.t('getStarted')}
                    </Button> */}
                    <Box sx={{ display: { xs: 'none', md: 'block' } }} ml={3}>
                        <DownloadShortCut />
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }} ml={0}>
                        <BilingSwitchBtn />
                    </Box>
                    {/* <Button
                        variant="text"
                        href="/tdx/register"
                        component={Link}
                        sx={{
                            fontSize: '.875rem',
                            lineHeight: '1rem',
                            fontFamily: 'GothamBold',
                            fontWeight: 'Bold',
                            display: {
                                xs: 'block',
                                md: 'none'
                            }
                        }}
                    >
                        {i18n.t('register')}
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        href="/sign"
                        component={Link}
                        sx={{
                            padding: {
                                md: '.5rem 1rem',
                                sx: 'px 1rem'
                            },
                            lineHeight: '100%',
                            marginLeft: '2rem',
                            fontSize: '.875rem',
                            fontFamily: 'GothamBold',
                            fontWeight: 'Bold',
                            textTransform: 'none',
                            display: {
                                xs: 'block',
                                md: 'none'
                            }
                        }}
                    >
                        Register
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        onClick={goToTele}
                        component={Link}
                        sx={{
                            padding: {
                                md: '.5rem 1rem',
                                sx: 'px 1rem'
                            },
                            lineHeight: '100%',
                            marginLeft: '.875rem',
                            fontSize: '.875rem',
                            fontFamily: 'GothamBold',
                            fontWeight: 'Bold',
                            textTransform: 'none',
                            display: {
                                xs: 'block',
                                md: 'none'
                            }
                        }}
                    >
                        {i18n.t('login')}
                    </Button> */}
                    {/* <IconButton
                        color="inherit"
                        size="large"
                        sx={{
                            display: {
                                lg: 'none'
                            },
                            p: 0
                        }}
                    >
                        <img alt="" src={MenuIcon} />
                    </IconButton> */}
                     {/* <Box sx={{ display: {  md: 'none' } }} ml={0}>
                        <BilingSwitchBtn />
                    </Box> */}
                  
                    <DrawerMenuIcon onDrawerChange={(_open:boolean)=>setDrawer(_open)} />
                </Stack>
            </Box>
            {/* <ClickAwayListener onClickAway={handleClose}>
                <Collapse in={open}>
                    <PopoverMenu handleClick={handleClose} />
                </Collapse>
            </ClickAwayListener> */}
        </>
    );
};

export default Header;
